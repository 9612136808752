import cn from "classnames"
import s from "./ProductDetailsPopup.module.scss"
// import { Categories } from "../types"
// import _categories from "../../categories.json"
import { ProductHit } from "../../scrape-beepr"
import ProductImage from "../components/ProductImage"
import { getProductUnit } from "../utils"
import Co2 from "../components/Co2"
import Icon from "../components/Icon"

// const categories: Categories = _categories

interface ProductDetailsPopupProps {
  product: ProductHit
  closeFilterPopup: () => void
}

export default function ProductDetailsPopup({ product, closeFilterPopup }: ProductDetailsPopupProps): JSX.Element {

  return (
    <div className={cn(s["wrapper"])}>
      <div className={cn(s["popup__backdrop"])} onClick={closeFilterPopup}></div>
      <div className={cn(s["popup"])}>
        <span className={cn(s["popup__close"])} onClick={closeFilterPopup}><Icon type="x" /></span>
        <ProductImage id={product.id} className={s["popup__product-image"]} />
        <h4 className={cn(s["popup__title"], "text-center")}>
          {product.Name}
        </h4>
        <p className={cn(s["popup__subtitle"], "text-center")}>
          {getProductUnit(product)} / {product.Brand}
        </p>
        <label className="text-center">Estimeret CO2:</label>
        {product.lcaValue && (
          <>
            <Co2 co2={product.lcaValue?.co2Estimate} className="text-center" style={{ display: "block", fontSize: "2em" }} />
            <br />
            <label>Klima-score:</label>
            <p>{product.lcaValue?.score} (ud af 10)</p>
            <label>CO2-aftryk:</label>
            <p>Estimeret til {product.lcaValue.co2Estimate} kg CO2 pr. enhed</p>
            <label>Beskrivelse af klimaaftryk:</label>
            <p>{product.lcaValue?.scoreConsiderations}</p>
          </>
        )}
        {/* <label>Beskrivelse</label>
        <p>{product.LongDesc.slice(0, 120)}</p> */}
        {/* {product.Labels.length > 0 && (
          <>
            <label>Mærkning</label>
            {product.Labels.map((label, i) => (label.DisplayName + (i !== product.Labels.length -1 ? ", " : "" )))}
          </>
        )} */}
        
      </div>
    </div>
  )
}