import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./Note.module.scss"
import _categories from "../../categories.json"
import _aliases from "../../aliases.json"
import { Categories, ShoppingList, Wish, WishCategory } from "../types"
import StoreLogo from "../components/StoreLogo"
import Price from "../components/Price"
import Tag from "../components/Tag"
import ProductTable from "./ProductTable.module"
import Search from "./Search.module"
// import ShoppingListCTA from "./ShoppingListCTA.module"
import Frontpage from "./Frontpage.module"
import { getLocalStorage, getWishName, saveCategoryPreferences, setLocalStorage, getSharedWishList } from "../utils"
import FullListView from "./FullListView.module"
import { toast } from 'react-hot-toast'
import Co2 from "../components/Co2"
import Icon from "../components/Icon"

const categories: Categories = _categories

export default function Note(): JSX.Element {
  const preselections = [categories["Mælk/Letmælk"], categories["Brød og Boller/Rugbrød"], categories["Husholdning/Toiletpapir"], categories["Kød/Hakket oksekød"], categories["Surmælksprodukter/Yoghurt"], categories["Frugter/Bananer"]]
  // const preselections: ProductType[] = getProductTypesFromCategories(categories)
  // const preselections: ProductType[] = Array.from(new Set(products.map(p => p.type)))
  const [screenFocus, setScreenFocus] = useState<"wishlist" | "results" | "fullList">("wishlist")
  const [selectedShoppingList, setSelectedShoppingList] = useState(0)
  const [isFetchingShoppingLists, setIsFetchingShoppingLists] = useState(false)
  const [wishList, setWishListBase] = useState<Wish[]>([])
  const [lastFetchedWishList, setLastFetchedWishList] = useState<Wish[]>([])
  const previousWishList = useRef<Wish[]>([])
  const setWishList = (newWishList: Wish[]) => {
    // previousWishList.current = JSON.parse(JSON.stringify(wishList))
    setWishListBase(newWishList)
  }
  const [shoppingLists, setShoppingLists] = useState<ShoppingList[]>([])
  const [categoryPreferences, setCategoryPreferences] = useState<Record<WishCategory["id"], WishCategory>>({})
  const [wishFilterIndex, setWishFilter] = useState(-1)
  const [hasFetchedLocalWishList, setHasFetchedLocalWishList] = useState(false)

  // Get saved wishList and preferences
  useEffect(() => {
    const savedWishList = getLocalStorage("wishList")
    if (savedWishList) {
      setWishList(savedWishList)
    }
    setHasFetchedLocalWishList(true)

    const savedCategoryPreferences = getLocalStorage("categoryPreferences")
    if (savedCategoryPreferences) {
      setCategoryPreferences(savedCategoryPreferences)
    }
  }, [])

  useEffect(() => {
    if (!hasFetchedLocalWishList) return
    async function fetchData(wishListId: string) {
      const sharedWishList = await getSharedWishList(wishListId)
      // compare with local wishList
      const isSame = JSON.stringify(sharedWishList) === JSON.stringify(wishList)
      if (isSame) return

      if (wishList.length === 0) {
        setWishList(sharedWishList)
      } else {
        const confirm = window.confirm("Du har allerede en indkøbsliste. Vil du erstatte den med den delte liste?")
        if (confirm)
          setWishList(sharedWishList)
      }
    }

    const urlParams = new URLSearchParams(window.location.search)
    const wishListId = urlParams.get("liste")
    if (wishListId)
      fetchData(wishListId)

  }, [hasFetchedLocalWishList])


  useEffect(() => {
    setLocalStorage("wishList", wishList)
    if (wishList.length === 0 || wishFilterIndex > -1) return
    if (JSON.stringify(wishList) === JSON.stringify(lastFetchedWishList)) return

    setLastFetchedWishList(wishList)

    saveCategoryPreferences(wishList)

    console.log("New wishlist", wishList)
    setIsFetchingShoppingLists(true)
    async function fetchData() {
      const res = await fetch("/api/cheapestStore", {
        method: "POST",
        body: JSON.stringify(wishList),
      })
      const resJson = await res.json()
      console.log("New cheapest stores", resJson)
      setShoppingLists(resJson)
      setIsFetchingShoppingLists(false)
    }
    fetchData()
  }, [wishList, wishFilterIndex, lastFetchedWishList])

  useEffect(() => {
    if (shoppingLists.length > 0) {
      console.log("Setting previous list", JSON.parse(JSON.stringify(wishList)))
      previousWishList.current = JSON.parse(JSON.stringify(wishList))
    }
    if (wishList.length > 0 && shoppingLists.length === 0 && previousWishList.current.length > 0) {
      // window?.["_paq"]?.push(['trackEvent', 'Product table', 'No stores found'])
      setWishList(previousWishList.current)
      toast.error("Ingen butikker har alle dine varer på lager. Vi har fjernet den seneste vare", {
        duration: 10000,
        position: "top-center",
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '14px',
        },
        iconTheme: {
          primary: 'white',
          secondary: 'black',
        },
      })
    }
  }, [shoppingLists])

  useEffect(() => {
    console.log({ screenFocus })
  }, [screenFocus])

  useEffect(() => {
    document.body.classList[wishList.length === 0 ? "remove" : "add"]("wishlist-active")
  }, [wishList])

  function updateWish(indexToToggle: number, action: string, option?: any) {
    window?.["_paq"]?.push(['trackEvent', 'Wishlist', 'Modify wish: ' + action, getWishName(wishList[indexToToggle])])
    setWishList((JSON.parse(JSON.stringify(wishList)) as Wish[]).map<Wish | null>((wish, index) => {
      if (index === indexToToggle) {
        if (action === "replace")
          wish = {
            ...wish,
            ...option,
          }
        if (action === "resetFilters")
          wish = {
            ...wish,
            organic: false,
            brands: [],
            filteredSubcategories: [],
          } as WishCategory
        if (action === "toggleOrganic")
          wish.organic = !wish.organic
        if (action === "remove")
          return null
        if (action === "increment")
          wish = {
            ...wish,
            count: (wish.count ? wish.count + 1 : 2),
          }
        if (action === "decrement") {
          if (wish.count === 1) updateWish(indexToToggle, "remove")
          else {
            wish = {
              ...wish,
              count: (wish.count ? wish.count - 1 : 1),
            }
          }
        }
        if (action === "toggleBrand" && wish.type === "category")
          wish = {
            ...wish,
            brands: (wish.brands || []).includes(option) ? (wish.brands || []).filter(brand => brand !== option) : (wish.brands || []).concat(option),
          }
        if (action === "toggleSize" && wish.type === "category")
          wish = {
            ...wish,
            minSize: wish.minSize === option ? null : option,
          }
        if (action === "toggleSubcategory" && wish.type === "category")
          wish = {
            ...wish,
            filteredSubcategories: (wish.filteredSubcategories || []).includes(option) ? (wish.filteredSubcategories || []).filter(subcategory => subcategory !== option) : (wish.filteredSubcategories || []).concat(option),
          }
        if (action === "toggleLactoseFree" && wish.type === "category")
          wish.lactoseFree = !wish.lactoseFree
      }
      return wish
    }).filter(Boolean) as Wish[])
  }

  function addWish(wish: Wish) {
    // If wish already in wishlist, increase count
    const existingWishIndex = wishList.findIndex((wishInWishList) => {

      const sameId = wishInWishList.id === wish.id
      const sameFilters = wishInWishList.organic === wish.organic
      
      let sameBrands = true
      if (wishInWishList.type === "category" && wish.type === "category") {
        const brands = [wishInWishList.brands || [], wish.brands || []]
        sameBrands = brands[0].length === brands[1].length && brands[0].every((brand) => brands[1].includes(brand))
      }

      return sameId && sameFilters && sameBrands
    })
    if (existingWishIndex > -1)
      updateWish(existingWishIndex, "increment", 1)
    else {
      if (wish.type === "category") {
        // Check if we have preferences for the added category wish
        if (categoryPreferences[wish.id]) {
          wish = { ...categoryPreferences[wish.id], ...wish }
        }
      }
      setWishList([{ ...wish, count: 1 }, ...wishList]) // Otherwise add
    }
  }

  async function shareList() {
    const res = await fetch("/api/shareList", {
      method: "POST",
      body: JSON.stringify({ wishList }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    const { wishListId } = await res.json()

    const url = `https://dagly.dk/?liste=${wishListId}`
    if (navigator.share === undefined) {
      // Copy url to clipboard
      navigator.clipboard.writeText(url)
      toast.success("Link kopieret!", {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '14px',
        },
        iconTheme: {
          primary: 'white',
          secondary: 'black',
        },
      })
    } else {
      const shareData = {
        title: "Dagly - Indkøbsliste",
        text: "Her er min indkøbsliste",
        url,
      }
      navigator.share(shareData)
    }

  }

  if (screenFocus === "fullList")
    return <FullListView list={shoppingLists[selectedShoppingList]} backToHome={() => setScreenFocus("wishlist")} />

  return (
    <div>
      <div className={cn(s["top"])}>
        <div className={cn(s["top__left"])}>
          {wishList.length === 0 && (
            <h1 className={cn(s["headline"])}>Find din indkøbsliste mest klimavenligt, samlet ét sted.</h1>
          )}

          <div className={cn(s["search-container"])}>
            <Search categories={categories} aliases={_aliases as WishCategory[]} wishList={wishList} addWish={addWish} />
          </div>

          {wishList.length === 0 && (
            <>
              <div className="mb-medium"><small>Mest søgte dagligvarer lige nu</small></div>
              <div className="tags" >
                {preselections.map((category) => (
                  <Tag
                    key={category.id}
                    onClick={() => {
                      addWish({ type: "category", ...category })
                      window?.["_paq"]?.push(['trackEvent', 'Wishlist', 'Add suggested category', category.name])
                    }}
                  >
                    {category.name}
                  </Tag>
                ))}
              </div>
            </>
          )}
        </div>
        
        <div className={cn(s["top__right"])}>
          {wishList.length === 0 && (
            <img src="/images/dagly app teaser.png" style={{ maxHeight: "450px", margin: "0 auto" }} />
          )}
        </div>
      </div>

      {wishList.length > 0 && (
        <>
          <div className="text-center mt-medium mb-medium" style={{ display: "flex", "justifyContent": "center" }}><b>{isFetchingShoppingLists ? "Finder dine varer..." : <><Icon type="shoppingBasket" />{`Dine varer (${wishList.length})`}</>}</b></div>
          {/* List */}
          {/* <div className={cn(s["lists"], {[s["list--active"]]: screenFocus === "wishlist"})} onClick={() => setScreenFocus("wishlist")}>
          <span className={cn(s["list__preview"])}>
            {wishList.length} vare{wishList.length > 1 ? "r" : ""} i indkøbslisten
          </span>
          <ul className={cn(s["list__wishes"])}>
            {wishList.map((wish, itemI) => (
              <li className={cn(s["wish"])} key={getWishName(wish) + itemI} >
                  <Popover >
                    <Popover.Trigger>
                      <span className={cn(s["wish__name"])}>{getWishName(wish)}</span>
                    </Popover.Trigger>
                    <Popover.Content>
                      {wish.type === "category" && (

                        <Button.Group vertical light>
                          {wish.children?.map((subType) => (
                            <Button key={subType} onPress={() => updateWish(itemI, "replace", {...categories[subType], type: "category"})}>{subType}</Button>
                          ))}
                        </Button.Group>
                      )}
                    </Popover.Content>
                  </Popover>
                <Button.Group className={cn(s["wish__details"])} size="xs" light>
                  <Button disabled={wish.type === "product"} onPress={() => updateWish(itemI, "toggleOrganic")}><Icon type="organic" style={{opacity: wish.organic ? 1 : 0.4}} /></Button>
                  <Button onClick={() => updateWish(itemI, "remove")}>X</Button>
                  <Button>{wish.count || 1}</Button>
                </Button.Group>
              </li>
            ))}
          </ul>
        </div> */}


          {/* Shopping lists */}
          <div className={cn(s["list"], {
            [s["list--active"]]: screenFocus === "results",
            [s["list--loading"]]: isFetchingShoppingLists && shoppingLists.length > 1,
          })} >
            <div className={cn(s["list__tab__viewport"])}>
              <div className={cn(s["list__tab__container"])}>
                {isFetchingShoppingLists && shoppingLists.length === 0 && (Array(7).fill(0).map((_, i) => (
                  <div key={i} className={cn(s["list__tab"], s["list__tab--loading"], "skeleton-animation ", i === 0 ? s["list__tab--active"] : null)}></div>
                )))}
                {shoppingLists.map((list, i) => (
                  <div key={list.store} className={cn(s["list__tab"], { [s["list__tab--active"]] : i === selectedShoppingList, [s["list__tab--has-bulk-discount"]]: list.hasBulkDiscount })} onClick={() => {window?.["_paq"]?.push(['trackEvent', 'Product table', 'Change store tab', list.store, i]); setSelectedShoppingList(i)}}>
                    <div className={cn(s["list__tab__corners"])}></div>
                    <div className={cn(s["list__tab__logo"])}><StoreLogo style="logo" store={list.store} /></div>
                    <Co2 co2={list.totalCo2} />
                    <hr />
                    <Price price={list.total} decimalStyle={"lifted"} />
                  </div>
                ))}
              </div>
            </div>
            <div className={cn(s["list__product_table"])}>
              {isFetchingShoppingLists && shoppingLists.length === 0 && (
                <div className={cn(s["list__product_table__loading"])}>
                  <div className={cn(s["list__product_table__loading__spinner"])}></div>
                  {/* Skeleton product images */}
                  {Array(3).fill(0).map((_, i) => (
                    <div key={i} className={cn(s["list__product_table__loading__product"], "skeleton-animation")} style={{ opacity: (3-i) / 3 }}></div>
                  ))}
                </div>
              )}
              <ProductTable
                products={shoppingLists[selectedShoppingList]?.products}
                storeName={shoppingLists[selectedShoppingList]?.store}
                wishList={wishList}
                updateWish={updateWish}
                wishFilterIndex={wishFilterIndex}
                setWishFilter={setWishFilter}
              />
              <div className={cn(s["list__cta__container"])}>
                <div className={cn(s["list__cta__button"], s["list__cta__button"])} onClick={() => {shareList(); window?.["_paq"]?.push(['trackEvent', 'Product table', 'Share list'])}}>Del liste</div>
                <div className={cn(s["list__cta__button"], s["list__cta__button--secondary"])} onClick={() => {setWishList([]); setShoppingLists([]); window?.["_paq"]?.push(['trackEvent', 'Product table', 'Clear list'])}}>Ryd liste</div>
                {/* <ShoppingListCTA className={cn(s["list__cta__button"])} products={shoppingLists[selectedShoppingList]?.products} storeName={shoppingLists[selectedShoppingList]?.store} goToListView={() => setScreenFocus("fullList")} /> */}
              </div>
            </div>

            {/* <span className={cn(s["list__preview"])}>
            {shoppingLists?.[0] && (
              <>
                <StoreLogo store={shoppingLists?.[0]?.store} />: <Price price={shoppingLists?.[0]?.total} />
              </>
            )}
          </span>
          <Collapse.Group accordion={false} splitted={true} className={cn(s["list__results"])}>
            {shoppingLists.map((list, i) => (
              <Collapse
                expanded={i === 0 && list.products.length > 0}
                contentLeft={
                  <div>
                    <StoreLogo store={list.store} />: <Price price={list.total} />
                    <ShoppingListCTA products={list.products} storeName={list.store} />
                  </div>
                }
                title=" "
                shadow={true}
                key={list.store}
              >
                <ProductTable
                  products={list.products as ProductHit[]}
                  storeName={list.store}
                />
              </Collapse>
            ))}
          </Collapse.Group> */}
          </div>
        </>
      )}

      {wishList.length === 0 && (
        <Frontpage />
      )}
    </div>
  )
}

