import cn from "classnames"
import s from "./ProductImage.module.scss"

type ProductImageProps = {
  id: string
  className?: string
  onClick?: () => void
}
export default function ProductImage({ id, className, onClick = void 0 }: ProductImageProps): JSX.Element {

  return (
    <div className={cn(s["product__image"], className)} onClick={onClick}>
      <div className={cn(s["product__image__fallback"])}></div>
      <div style={{ backgroundImage: `url(https://onestopshop-images.vercel.app/images/${id}.jpg)` }}></div>
    </div>
  )
}