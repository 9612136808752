/* eslint-disable no-irregular-whitespace */
import React from "react"


interface PriceProps {
  price: number
  withCurrency?: boolean
  decimalStyle?: null | "lifted" | ",-" | "rounded"
}

export default function Price({ price, withCurrency = true, decimalStyle = null }: PriceProps): JSX.Element {
  function formatPrice(num: number) {
    return num.toLocaleString("da-DK", { currency: "DKK", style: "currency" } )
  }
  const dollarsAndCents = formatPrice(price).split(" ")[0]
  const dollars = dollarsAndCents.includes(",") ? dollarsAndCents.split(",")[0] : dollarsAndCents.split(".")[0] 
  const cents = dollarsAndCents.includes(",") ? dollarsAndCents.split(",")[1] : dollarsAndCents.split(".")[1]
  const currency = formatPrice(price).split(" ")[1]
  return (
    <span className={["price"].join(" ")}>
      {!decimalStyle && (
        <>
          <span className="value">{dollarsAndCents} </span>
          {withCurrency && <span className="currency">{currency}</span>}
        </>
      )}
      {decimalStyle === "lifted" && (
        <>
          <span className="value">{dollars}</span><sup>{cents}</sup> 
          {withCurrency && <span className="currency">{currency}</span>}
        </>
      )}
      {decimalStyle === ",-" && (
        <span className="value">{Math.round(price)},-</span>
      )}
    </span>
  )
}

