import { useEffect, useState } from "react"
import cn from "classnames"
import s from "./WishFilter.module.scss"
import { Size, Wish } from "../types"
import { getCategoryName, getWishName, isLactoseFreeCategory } from "../utils"

import Tag from "../components/Tag"
import ScrollContainer from "../components/ScrollContainer"
import Icon from "../components/Icon"


interface WishFilterProps {
  wish: Wish
  updateWish: (action: string, option?: any) => void
  closeFilterPopup: () => void
}

export default function WishFilter({ wish, updateWish, closeFilterPopup }: WishFilterProps): JSX.Element {
  const [brands, setBrands] = useState<string[]>([])
  const [sizes, setSizes] = useState<Size[]>([])

  useEffect(() => {
    async function fetchData() {
      if (wish.type === "category") {
        const filterRes = await fetch(`/api/getFilters?category=${encodeURIComponent(wish.id)}`)
        const filterJson: { brands: any, sizes: Size[] } = await filterRes.json()
        console.log(filterJson)
        setBrands(filterJson.brands)
        setSizes(filterJson.sizes)
      }
    }
    fetchData()
  }, [])

  return (
    <div className={cn(s["wishfilter"])}>
      <div className={cn(s["wishfilter__popup__backdrop"])} onClick={closeFilterPopup}></div>
      <div className={cn(s["wishfilter__popup"])}>
        <div className={cn(s["wishfilter__popup__header"])}>
          <h4 className={cn(s["wishfilter__popup__title"])}>{getWishName(wish)}</h4>
          <span onClick={closeFilterPopup}><Icon type="x" /></span>
        </div>
        <div className={cn(s["wishfilter__popup__filters"])}>
          <label>Mærkning</label>
          <ScrollContainer className="tags">
            <Tag onClick={() => updateWish("toggleOrganic")} active={wish.organic}>Økologisk</Tag>
            {wish.type === "category" && isLactoseFreeCategory(wish.id) && (
              <Tag onClick={() => updateWish("toggleLactoseFree")} active={wish.lactoseFree}>Laktosefri</Tag>
            )}
          </ScrollContainer>

          {wish.type === "category" && wish.children && wish.children.length > 0 && (
            <>
              <label>Type</label>
              <ScrollContainer className="tags">
                {wish.children.map((subcategory) => (
                  <Tag key={subcategory} active={wish.filteredSubcategories?.includes(subcategory)} onClick={() => updateWish("toggleSubcategory", subcategory)}>{getCategoryName(subcategory)}</Tag>
                ))}
              </ScrollContainer>
            </>
          )}
          {brands.length > 0 && (
            <>
              <label>Producent</label>
              <ScrollContainer className="tags">
                {brands.map((brand) => (
                  <Tag key={brand} active={wish.type === "category" && wish.brands?.includes(brand)} onClick={() => updateWish("toggleBrand", brand)}>{brand}</Tag>
                ))}
              </ScrollContainer>
            </>
          )}
          {sizes.length > 0 && (
            <>
              <label>Minimumsmængde</label>
              <div className="tags">
                {sizes.map((size) => (
                  <Tag key={size.Units + size.Uom} active={wish.type === "category" && ((wish.minSize?.Units || "") + (wish.minSize?.Uom || "")) === size.Units + size.Uom} onClick={() => updateWish("toggleSize", size)}>{size.Units}{size.Uom}</Tag>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="text-center m-medium">
          <span onClick={() => { updateWish("resetFilters") }}>Ryd filtrer</span> | <span onClick={() => { updateWish("remove"); closeFilterPopup() }}>Slet vare</span>
        </div>
      </div>
    </div>
  )
}