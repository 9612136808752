import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./Search.module.scss"
import Tag from "../components/Tag"
import { Categories, Category, Wish, WishCategory } from "../types"
import levensthein from "../levensthein"
import { ProductHit } from "../../scrape-beepr"
import { debounce } from "../utils"
import Icon from "../components/Icon"
import ProductImage from "../components/ProductImage"

interface SearchProps {
  wishList: Wish[]
  addWish: (wish: Wish) => void
  categories: Categories
  aliases: WishCategory[]
}


declare global {
  interface Window {
    _paq: any
  }
}

export default function Search({ addWish, categories, aliases }: SearchProps): JSX.Element {
  
  const [categoriesSearchResult, setCategoriesSearchResult] = useState<(Category | WishCategory)[]>([])
  const [productsSearchResult, setProductsSearchResult] = useState<ProductHit[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const searchInput = useRef<HTMLInputElement>(null)
  const searchContainer = useRef<HTMLInputElement>(null)
  const categoryIds = Object.keys(categories)

  // useEffect(() => {
  //   if (isFocusedOnSearch && typeof window !== "undefined" && searchInput?.current) {
  //     // searchInput.current.scrollIntoView({behavior: "smooth", })
  //     window.scrollTo({
  //       top: searchInput.current.getBoundingClientRect().top + window.pageYOffset - 120,
  //       behavior: 'smooth'
  //     });
  //   }
  // }, [isFocusedOnSearch])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchContainer.current && !searchContainer.current.contains(event.target as Node)) {
        setIsSearching(false)
      }
      if (searchInput.current && searchInput.current?.value?.length > 2 && searchInput.current.contains(event.target as Node)) {
        setIsSearching(true)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  const trackSearchDebounced = debounce((searchTerm: string) => {
    window?.["_paq"]?.push(['trackSiteSearch', searchTerm])
    window?.["_paq"]?.push(['trackEvent', 'Search', 'Search', searchTerm])
  }, 1500)


  const handleSearch = debounce((searchTerm: string) => {
    setIsSearching(searchTerm.length > 1)
    if (searchTerm.length < 2) return


    // Search aliases
    const aliasesResult = aliases.filter(alias => alias.name.toLowerCase().includes(searchTerm.toLowerCase()))
    const getCategoryChildrens = (categoryId: string) => categories[categoryId]?.children
    const aliasesScore: [WishCategory, number][] = aliasesResult.map(alias => {return [{ ...alias, children: getCategoryChildrens(alias.id) }, levensthein(alias.name, searchTerm)]})
    // Search categories
    const searchResultCategoryId = categoryIds.filter(category => category.toLowerCase().includes(searchTerm.toLowerCase()))

    const categoriesScore = searchResultCategoryId.reduce<[Category, number][]>((acc, categoryId) => {
      // const lowestScore = Math.min(...categoryId.split("/").filter(Boolean).map((pathPart) => levensthein(pathPart, searchTerm)))
      const lowestScore = levensthein(categoryId.split("/").slice(-1)[0], searchTerm)
      const category = categories[categoryId]
      acc.push([category, lowestScore])
      return acc
    }, [])

    const topResults = [...categoriesScore, ...aliasesScore].sort(([,scoreA], [,scoreB]) => scoreA - scoreB).slice(0,5)
    setCategoriesSearchResult(topResults.map(([item]) => item))

    // Search products
    setIsFetching(true)
    async function fetchData() {
      const res = await fetch(`/api/searchProducts?q=${encodeURIComponent(searchTerm)}`)
      const resJson = await res.json()
      console.log(resJson)
      setIsFetching(false)
      setProductsSearchResult(resJson)
    }
    fetchData()


  }, 250)


  return (
    <div className={cn(s["search"], { [s["search--is-searching"]]: isSearching })}>
      <div className={cn(s["search__dropdown"])} ref={searchContainer}>
        <div className={cn(s["search__bar-container"])}>
          <span className={cn(s["search__magnifying-glass"])} onClick={() => searchInput?.current?.focus()}><Icon type="magnifyingGlass" /></span>
          <input className={cn(s["search__input"])} ref={searchInput} placeholder="Søg efter varer" onChange={(e) => {handleSearch(e.target.value); trackSearchDebounced(e.target.value)}} />
        </div>
        <div className={cn(s["search__results"])}>
          {categoriesSearchResult.length > 0 && (
            <>
              <p>Variant:</p>
              <div className="tags mb-large">
                {categoriesSearchResult.map((item) => (
                  <Tag
                    key={item.id}
                    onClick={() => {
                      addWish({ ...item, type: "category" });
                      (searchInput?.current as unknown as HTMLInputElement).value = ""
                      window?.["_paq"]?.push(['trackEvent', 'Wishlist', 'Add category', item.name])
                      // (searchInput?.current as unknown as HTMLInputElement).focus()
                      handleSearch("")
                    }}
                  >
                    {item.name}
                  </Tag>
                ))}
              </div>
            </>
          )}
          {productsSearchResult.length > 0 && (
            <div className={cn(s["product-results"])}>
              <p>Produkter:</p>
              {productsSearchResult.map((p) => (
                <div className={cn(s["product-results__product"])} key={p.id} onClick={() => {
                  addWish({ Name: p.Name, id: p.id, Brand: p.Brand, Category: p.Path, type: "product", organic: p.isOrganic });
                  (searchInput?.current as unknown as HTMLInputElement).value = ""
                  window?.["_paq"]?.push(['trackEvent', 'Wishlist', 'Add product', p.Name])
                  // (searchInput?.current as unknown as HTMLInputElement).focus()
                  handleSearch("")
                }}>
                  <ProductImage id={p.id} />
                  <span className={cn(s["product-results__product__name"])} >{p.Name}</span>
                  <span className={cn(s["product-results__product__plus"])} >+</span>
                </div>
              ))}
            </div>
          )}

          {isFetching === false && categoriesSearchResult.length === 0 && productsSearchResult.length === 0 && (
            <p className={cn(s["search__results__no-results"])}>Ingen resultater</p>
          )}
        </div>
      </div>

    </div>
  )
}