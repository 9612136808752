import React, { useEffect, useRef } from "react"
import cn from "classnames"

interface TagProps {
  children: React.ReactNode
  onClick?: () => void
  className?: string
  active?: boolean
}

export default function ScrollContainer(props: TagProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!scrollRef.current || !containerRef.current) return

    if (scrollRef.current.scrollHeight > scrollRef.current.clientHeight) {
      containerRef.current.classList.add("scrollable")
    }
    // if (ele.current.scrollTop > 0) {
    //   ele.current.classList.add("scrolled")
    // }
  }, [props.children])
  return (
    <div className={cn("scroll-container")} ref={containerRef}>
      <div className={cn(props.className)} ref={scrollRef}>
        {props.children}
      </div>
    </div>
  )
}

