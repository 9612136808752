import Head from 'next/head'
import Note from '../src/modules/Note.module'

export default function Home() {
  return (
    <div className='container' style={{ paddingTop: "16px" }}>
      <Head>
        <title>Dagly</title>

        <meta name="title" content="Dagly" />
        <meta name="description" content="Det skal ikke være svært at gøre en forskel for klimaet, når du handler dagligvarer." />
        <meta property="og:image" content="https://dagly.dk/images/dagly+preview+image.png" />


        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Note />

    </div>
  )
}
