import cn from "classnames"
import s from "./FullListView.module.scss"
import sNote from "./Note.module.scss"
import { ShoppingList } from "../types"
import Icon from "../components/Icon"
import ProductTable from "./ProductTable.module"
import StoreLogo from "../components/StoreLogo"
import Price from "../components/Price"


interface FullListViewProps {
  list: ShoppingList
  backToHome: () => void
}

export default function FullListView({ list, backToHome }: FullListViewProps): JSX.Element {


  return (
    <div className={cn(s["full-list"])}>
      <div onClick={() => backToHome()} style={{ fontSize: "26px", marginLeft: "-0.3em" }}><Icon type="backChevron" /></div>
      <div style={{ display: "flex", marginBottom: "2em" }}>
        <h4 style={{ display: "inline" }}>Din liste - {list.products.length} varer</h4>
      </div>
      <div className={cn(s["full-list__product-table-container"])}>
        <div className={cn(sNote["list__tab__viewport"])}>
          <div className={cn(sNote["list__tab__container"])}>
            <div key={list.store} className={cn(sNote["list__tab"], sNote["list__tab--active"])}>
              <div className={cn(sNote["list__tab__corners"])}></div>
              <div className={cn(sNote["list__tab__logo"])}><StoreLogo style="logo" store={list.store} /></div>
              <Price price={list.total} />
            </div>
          </div>
        </div>
        <ProductTable className={s["full-list__product-table"]} products={list.products} storeName={list.store} />
      </div>
    </div>
  )
}