import React from "react"
import cn from "classnames"

interface TagProps {
  children: React.ReactNode
  onClick?: () => void
  className?: string
  active?: boolean
}

export default function Tag(props: TagProps): JSX.Element {
  return (
    <span className={cn("tag", props.className, props.active ? "active" : null)} onClick={props.onClick} >
      {props.children}
    </span>
  )
}

