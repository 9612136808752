import cn from "classnames"
import s from "./Frontpage.module.scss"


export default function Frontpage(): JSX.Element {

  
  return (
    <div className={cn(s["frontpage"], "mt-xl")}>
      {/* <div className="text-center mb-medium" style={{ fontSize: "1.2em", fontWeight: "600" }}>Vi søger på tværs af markedets varer, og via AI-analyse guider vi til indkøb med lavt klimaaftryk.</div> */}
      {/* <div className="edge-to-edge-mobile hide-scrollbar" style={{ overflow: "scroll hidden", paddingLeft: "var(--large)", marginBottom: "50px", textAlign: "center" }}>
        <img className="hide-on-mobile" style={{ "maxWidth": "none", "height": "68px", paddingRight: "12px" }} src="/images/store-logo-ribbon.png" alt="Butikker som Dagly understøtter" />
        <img className="hide-on-desktop" style={{ "maxWidth": "none", "height": "58px", paddingRight: "12px" }} src="/images/store-logo-ribbon.png" alt="Butikker som Dagly understøtter" />
      </div> */}
      <div className="hide-on-mobile" style={{ height: "0px", marginBottom: "120px" }}></div>
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <h2>Det skal ikke være svært at gøre en forskel for klimaet, når du handler dagligvarer.</h2>
        <p>Dine daglige indkøb har stor betydning for klimaet. Vi hjælper med at finde hele din indkøbsliste mest klimavenligt, ved at søge på tværs af markedets produkter og deres klimaaftryk.</p>
      </div>
      {/* <img className="hide-on-desktop" src="/images/dagly app teaser.png" /> */}
      <div className="hide-on-mobile" style={{ height: "0px", marginBottom: "120px" }}></div>
      <h4 className="mt-xxl mb-medium hide-on-desktop">Så nemt er det at bruge Dagly.</h4>
      <h4 className="mt-xxl mb-large hide-on-mobile">Så nemt er det at bruge Dagly.</h4>
      <div className={cn(s["tutorial"])}>
        <div>
          <img src="/images/dagly-tutorial-1.png" />
          <span>
            <strong>Tilføj dagligvarer</strong>
Som, hvis du skrev din indkøbsliste.
          </span>
        </div>
        <div>
          <img src="/images/dagly-tutorial-2.png" />
          <span>
            <strong>Se resultater</strong>
Vi søger og viser hvor din indkøbsliste er mest klimavenlig.
          </span>
        </div>
        <div>
          <img src="/images/dagly-tutorial-3.png" />
          <span><strong>Tilret præferencer</strong>
Søgningen opdateres og vi husker dine valg til næste gang.</span>
        </div>
        <div>
          <img src="/images/dagly-tutorial-4.png" />
          <span>
            <strong>Se varers klimaaftryk</strong>
Beskrivelser af de enkelte produkters estimerede klimaaftryk.
          </span>
        </div>

      </div>
      <div className="hide-on-mobile" style={{ height: "0px", marginBottom: "120px" }}></div>
      {/* <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <h4 className="mt-xxl">Automatiske indkøbslister, ugens billige retter og brug af AI.</h4>
        <p>Vi arbejdet løbende på at forbedre oplevelsen af Dagly. De nævnte features er blot nogle af de mange tilføjelser, som venter lige rundt om hjørnet.</p>
      </div> */}

      <div className="edge-to-edge-mobile disclaimer">
        <div className="logo-container" style={{ display: "flex", marginBottom: "36px" }}><img style={{ width: "120px" }} src="/dagly-logo.svg" alt="" /></div>

        <div className="" style={{ color: "black", opacity: 0.7, maxWidth: "600px", margin: "0 auto" }}>
          <p>Tak fordi du bruger Dagly til at nedbringe dit klimaaftryk, når du handler dagligvarer. </p>

          <p>Vores vision er at skabe et retvisende billede af dagligvaremarkedets og dets produkters klimaaftryk, så du kan handle ind med god samvittighed, og vi sammen kan passe på klimaet.</p>

          <p>Dagly bruger kunstig intelligens til at estimere klimaaftryk på markedets mange tusinde varer, og vores søgemaskine må derfor ikke anses som 100% sandfærdig information. Vi tager forbehold for eventuelle fejlvurderinger af produkters klimaaftryk, såvel eventuelle priser. </p>

          <p>Vi arbejder løbende på at inddrage producenternes egne klimarapporter og mærkninger i vores vurderingerne, såvel blande det med prisen på varen.  Er du producent eller supermarkedskæde, og vil gerne have hjælpe os med at have opdateret data? Så kontakt os på <a href="mailto:kontakt@dagly.dk">kontakt@dagly.dk</a>.</p>


          <p>All rights reserved – Dagly.dk – 2024</p>
        </div>
      </div>

    </div>
  )
}