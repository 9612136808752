import React, { Dispatch, SetStateAction, useRef, useState } from "react"
import cn from "classnames"
import s from "./ProductTable.module.scss"
import { StoreName, Wish } from "../types"
import Price from "../components/Price"
import { ProductHit } from "../../scrape-beepr"
import { getFiltersText, getPrice, getProductUnit, getWishName, isBulkSaleAvailable, titleCase } from "../utils"
import WishFilter from "./WishFilter.module"
import ProductDetailsPopup from "./ProductDetailsPopup.module"
import Icon from "../components/Icon"
import Co2 from "../components/Co2"

interface ProductTableProps {
  products: ProductHit[]
  storeName: StoreName
  wishList?: Wish[]
  updateWish?: (indexToToggle: number, action: string, option?: any) => void
  className?: string
  wishFilterIndex?: number
  setWishFilter?: Dispatch<SetStateAction<number>>
}


export default function ProductTable({ products, storeName, wishList, updateWish = () => { }, wishFilterIndex, setWishFilter, className }: ProductTableProps): JSX.Element {
  const [productDetailsPopupIndex, setProductDetailsPopupIndex] = useState(-1)
  const [countSettingsExpandedIndex, setCountSettingsExpandedIndex] = useState(-1)
  const listLengthDifference = wishList && products ? wishList?.length - products.length : 0
  const tableRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div className={cn(s["product-table"], className)} ref={tableRef}>
        {(products || []).map(function ProductRow(product, productIndex) {
          const wishListIndex = productIndex + listLengthDifference

          let touchXStart: number
          let touchYStart: number
          let scrollDirection: "horizontal" | "vertical" | null = null

          const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
            console.log({ e })
            const touch = e.touches[0]
            const touchX = touch.clientX
            const touchY = touch.clientY
            touchXStart = touchX
            touchYStart = touchY
            scrollDirection = null
            const touchEnd = (e: TouchEvent) => {
              const touch = e.changedTouches[0]
              const touchXEnd = touch.clientX
              if ((touchX - touchXEnd) > 100) {
                updateWish(wishListIndex, "remove")
              } else {
                const rowContainer = tableRef?.current && tableRef.current.children[productIndex] as HTMLDivElement
                if (rowContainer) {
                  rowContainer.style.setProperty("--percentToDelete", "0")
                  const row = rowContainer?.children[0] as HTMLDivElement
                  if(row)
                    row.style.left = "0px"
                }
              }
              document.removeEventListener("touchend", touchEnd)
            }
            document.addEventListener("touchend", touchEnd)
          }

          const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
            const touch = e.touches[0]
            const touchX = touch.clientX
            const touchY = touch.clientY

            // Determine if the user is trying to scroll vertically
            const deltaY = touchY - touchYStart
            const deltaX = touchX - touchXStart

            if (scrollDirection === null) {
              if (Math.abs(deltaY) > 20 && Math.abs(deltaY) > Math.abs(deltaX)) {
                scrollDirection = "vertical"
              } else if (Math.abs(deltaX) > 20 && Math.abs(deltaX) > Math.abs(deltaY)) {
                scrollDirection = "horizontal"
              }
            }
            // If the user is trying to scroll vertically, don't move the row
            if (scrollDirection === "horizontal") {
              const rowContainer = tableRef?.current && tableRef.current.children[productIndex] as HTMLDivElement
              if (rowContainer && deltaX < 0) {
                const percentToDelete = Math.abs(deltaX) >= 100 ? 1 : Math.min(Math.abs(deltaX) / 100, 1) * 0.4
                rowContainer.style.setProperty("--percentToDelete", `${percentToDelete}`)
                const row = rowContainer?.children[0] as HTMLDivElement
                if(row)
                  row.style.left = `${deltaX}px`
              }
            }

          }

          return (
            <div key={product.id} className={cn(s["product-table__row__container"])}>
              <div className={cn(s["product-table__row"])} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>

                {/* Product image */}
                <div onClick={() => setProductDetailsPopupIndex(wishListIndex)} className={cn(s["product-table__product__image"])}>
                  <div className={cn(s["product-table__product__image__fallback"])}></div>
                  <div style={{ backgroundImage: `url(https://onestopshop-images.vercel.app/images/${product.id}.jpg)` }}></div>
                </div>

                {/* Name */}
                <div className={cn(s["product-table__product__name"])}>
                  <div className="name">{titleCase(product["Name"])}</div>
                  <div className="details">{getProductUnit(product)}{product.Brand ? ` / ${product.Brand}` : ""}</div>

                  {wishList && (
                    <div className="filters" onClick={() => {
                      setWishFilter && setWishFilter(wishListIndex)
                      window?.["_paq"]?.push(['trackEvent', 'Product table', 'Open filter popup', getWishName(wishList[wishListIndex])])
                    }}>
                      <span><Icon type="pen" /></span>
                      {getFiltersText(wishList[wishListIndex]) ? "Dine valg: " + getFiltersText(wishList[wishListIndex]) : "Tilføj filtre"}
                    </div>
                  )}

                  {/* Old bottom, with product details */}
                  {/* <div className="bottom">
              {isProductOrganic(product) ? <span><Icon type="organic" /></span> : ""}
              <span>{getProductUnit(product)}</span>
              {product.Brand && !product.Name.toLowerCase().includes(product.Brand.toLowerCase()) ? `, ${product.Brand}`: ""}
              <span>, {product.count} stk.</span>
              </div> */}
                </div>

                {/* Count */}
                <div className={cn(s["product-table__product__count__wrapper"], countSettingsExpandedIndex === productIndex ? s["product-table__product__count--expanded"] : null)}>

                  <div className={cn(s["product-table__product__count"])}>
                    <span className={cn(s["product-table__product__count_simple"])} onClick={() => {if (wishList) { setCountSettingsExpandedIndex((prev) => prev === productIndex ? -1 : productIndex); window?.["_paq"]?.push(['trackEvent', 'Product table', 'Open count popup', getWishName(wishList[wishListIndex])])} }}>{product.count}</span>
                    <div className={cn(s["product-table__product__count_expanded"])}>
                      <span className="trash" onClick={() => {setCountSettingsExpandedIndex(-1); updateWish(wishListIndex, "remove")}}>
                        <Icon type="trashCan" />
                      </span>
                      <span onClick={() => {setCountSettingsExpandedIndex(-1); updateWish(wishListIndex, "decrement")}}>-</span>
                      <span onClick={() => {setCountSettingsExpandedIndex(-1); updateWish(wishListIndex, "increment")}}>+</span>
                    </div>
                  </div>
                </div>

                {/* Price */}
                <div className={cn(s["product-table__product__price"])} onClick={() => setProductDetailsPopupIndex(wishListIndex)}>
                  {product.lcaValue && (
                    <Co2 className={cn(s["product-table__product__co2"])} co2={product.lcaValue.co2Estimate} />
                  )}
                  {product.Prices[storeName]?.Discount && product.Prices[storeName]?.Discount.Saved > 0 && (wishList?.[wishListIndex]?.count || 1) >= product.Prices[storeName].Discount.MinQuantity && (
                    <div className={cn(s["product-table__product__price__original"])}>
                      <Price price={(product.Prices[storeName].Price + product.Prices[storeName].Discount.Saved) * (wishList?.[wishListIndex]?.count || 1)} decimalStyle={"lifted"} withCurrency={false} />
                    </div>
                  )}
                  <Price price={getPrice(product, storeName, (wishList?.[wishListIndex]?.count || 1))} decimalStyle={"lifted"} withCurrency={false} />

                  {product?.Prices[storeName]?.PricePerUnit && (
                    <div className={cn(s["product-table__product__price__unit-price"])}>
                      <Price price={product.Prices[storeName].PricePerUnit} withCurrency={true} />
                      {/* <span> /stk.</span> */}
                    </div>
                  )}
                  {
                    isBulkSaleAvailable(product, storeName, wishList?.[wishListIndex]?.count || 1) && (
                      <div className={cn(s["product-table__product__price__bulk-sale"])} onClick={() => updateWish(wishListIndex, "replace", { count: product.Prices[storeName].Discount.MinQuantity })}>
                        {product.Prices[storeName].Discount.MinQuantity} stk. <Price price={product.Prices[storeName].Discount.MinQuantity * (product.Prices[storeName].Price - product.Prices[storeName].Discount.SavedPerItem)} decimalStyle={",-"} />
                      </div>
                    )}
                </div>
              </div>
              <div className={cn(s["product-table__row__background"])}>
                <Icon type="trashCan" />
              </div>
            </div>
          )
        })}
      </div>
      {wishList && typeof wishFilterIndex === "number" && wishFilterIndex > -1 && (<WishFilter wish={wishList[wishFilterIndex]} updateWish={(action: string, option?: any) => updateWish(wishFilterIndex, action, option)} closeFilterPopup={() => setWishFilter && setWishFilter(-1)} />)}
      {wishList && productDetailsPopupIndex !== -1 && (<ProductDetailsPopup product={products[productDetailsPopupIndex]} closeFilterPopup={() => setProductDetailsPopupIndex(-1)} />)}
    </>
  )

  // const columns = [
  //   {
  //     key: "image",
  //     label: "",
  //   },
  //   {
  //     key: "Name",
  //     label: "Navn",
  //   },
  //   // {
  //   //   key: "count",
  //   //   label: "Antal",
  //   // },
  //   {
  //     key: "prices",
  //     label: "Pris",
  //   },
  // ] as const;

  // type Sgg = (typeof columns)[number]["key"]

  // return (
  //   <Table>
  //     <Table.Header columns={[...columns]}>
  //       {(column) => (
  //         <Table.Column key={column.key}>{column.label}</Table.Column>
  //       )}
  //     </Table.Header>
  //     <Table.Body items={products}>
  //       {(item) => (
  //         <Table.Row key={item.Name}>
  //           {(columnKey) => {
  //             if(columnKey === "image")
  //               return <Table.Cell><div className={cn(s["product-table__product__image"])} style={{backgroundImage: `url(https://onestopshop-images.vercel.app/images/${item.id}.jpg)`}} /></Table.Cell>
  //             if(columnKey === "Name")
  //               return (
  //                 <Table.Cell>
  //                   <div className={cn(s["product-table__product__name"])}>
  //                     <div className="top">{item["Name"]}</div>
  //                     <div className="bottom">
  //                       {isProductOrganic(item) ? <span><Icon type="organic" /></span> : ""}
  //                       <span>{getProductUnit(item)}</span>
  //                       {item.Brand && !item.Name.includes(item.Brand) ? `, ${item.Brand}`: ""}
  //                       <span>, {item.count} stk.</span>
  //                     </div>
  //                   </div>
  //                 </Table.Cell>
  //               )
  //             if(columnKey === "count")
  //               return <Table.Cell>{item.count}</Table.Cell>
  //             if(columnKey === "prices")
  //               return <Table.Cell><Price price={item.Prices[storeName].Price} /></Table.Cell>
  //             if(columnKey === "organic")
  //               return <Table.Cell>{isProductOrganic(item) ? <Icon type="organic" /> : ""}</Table.Cell>
  //             else
  //               return <Table.Cell> </Table.Cell>
  //           }}
  //           {/* {(columnKey: (typeof columns)[number]["key"]) => <Table.Cell>{columnKey === "prices" ? item.prices[storeName] : (item[columnKey] as string)}</Table.Cell>} */}
  //         </Table.Row>
  //       )}
  //     </Table.Body>
  //   </Table>
  // );
}

