import { Category, Wish, StoreName } from "./types"
import { ProductHit } from "../scrape-beepr"


export function getProductTypesFromCategories(categories: Category[]) {
  return categories.reduce<string[]>((acc, curr) => {
    const productTypes = curr?.children || []
    acc.push( ...productTypes)
    return acc
  }, [])
}

export function getWishName(wish: Wish) {
  if (wish.type === "product") {
    return wish.Name
  }
  if (wish.type === "category") {
    return wish.name
  }
  return ""
}

export function isProductOrganic(product: ProductHit) {
  return product.Labels?.some(label => label.ParentID === "okologi" && label.ID !== "noglehulsmarket")
}
export function isProductLactoseFree(product: ProductHit) {
  return product.Labels?.find(label => label.id === "laktosefri")
}
export function isLactoseFreeCategory(category: Category["id"]) {
  const categoriesWithLactoseOptions = ["Mælk", "Brød og Boller", "Ost", "Pålæg", "Surmælksprodukter"]
  return categoriesWithLactoseOptions.some(lactoseCategory => category.split("/").includes(lactoseCategory))

}
export function getProductUnit(product: ProductHit) {
  if (product.Units >= 1000) {
    if (product.Uom === "gr")
      return Math.round(product.Units / 1000) + " kg."
    if (product.Uom === "ml")
      return Math.round(product.Units / 1000) + " l."
  }
  return Math.round(product.Units) + " " + product.Uom + "."
}

export function debounce(callback: (args: any) => void, wait: number) {
  let timeoutId: any = null
  return (...args: any) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args)
    }, wait)
  }
}

export function storeNameToHuman(storeName: StoreName): string {
  const map: {[key in StoreName]: string} = {
    coop365: "365discount",
    superbrugsen: "SuperBrugsen",
    føtex: "Føtex",
    nemlig: "Nemlig.com",
    netto: "Netto",
    rema: "Rema 1000",
    bilka: "Bilka",
    gorillas: "Gorillas",
    minkobmand: "Min Købmand",
    spar: "Spare",
  }
  return map[storeName]
}

export function getStoreLink(storeName: StoreName): string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const map: Partial<{[key in StoreName]: string}> = {
    nemlig: "https://www.nemlig.com/",
    gorillas: "https://gorillas.io/",
  }
  return map[storeName] || ""
}

export function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)     
  }
  // Directly return the joined string
  return splitStr.join(' ') 
}

export function getCategoryName(category: Category["id"]) {
  return category.split("/").slice(-1)[0]
}

export function getFiltersText(wish: Wish) {
  if (!wish) return ""

  const filters = []
  if (wish.type === "category") {
    if (wish.organic)
      filters.push("Øko.")
    if (wish?.brands?.length)
      filters.push(wish.brands.join("/"))
    if (wish?.filteredSubcategories?.length)
      filters.push(wish.filteredSubcategories.map(getCategoryName).join("/"))
    // if (wish.name && filters.length > 0)
    //   filters.splice(0, 0, wish.name)
  }

  return filters.join(", ")
}

// Get JSON object from localStorage
export function getLocalStorage(key: string) {
  const storedData = localStorage.getItem(key)
  if (storedData) {
    try {
      return JSON.parse(storedData)
    } catch (error) {
      console.error(`Error parsing JSON for key "${key}":`, error)
    }
  }
  return null // Return null if the object doesn't exist or parsing fails
}

// Save JSON object to localStorage
export function setLocalStorage(key: string, value: any) {
  try {
    const jsonData = JSON.stringify(value)
    localStorage.setItem(key, jsonData)
  } catch (error) {
    console.error(`Error saving JSON for key "${key}":`, error)
  }
}

export function saveCategoryPreferences(wishList: Wish[]) {
  const categoryWishesWithPreferences = wishList.filter(wish => {
    if (wish.type === "category") {
      const isWishWithPreferences = wish.brands?.length
        || wish.filteredSubcategories?.length
        || (typeof wish.lactoseFree !== "undefined")
        || (typeof wish.organic !== "undefined")

      return isWishWithPreferences
    }
  })

  const existingPreferences = getLocalStorage("categoryPreferences") || {}

  const existingAndNewPreferences = existingPreferences
  categoryWishesWithPreferences.forEach(preference => existingAndNewPreferences[preference.id] = preference)
  console.log("Saving these preferences in localStorage", existingAndNewPreferences)
  setLocalStorage("categoryPreferences", existingAndNewPreferences)
}

export function isBulkSaleAvailable(product: ProductHit, storeName: StoreName, currentQuantity: number) {
  const storePrice = product?.Prices[storeName]
  if (!storePrice) return false
  return storePrice.Discount && storePrice.Discount.MinQuantity > currentQuantity
}
export function getPrice(product: ProductHit, storeName: StoreName, quantity: number) {
  const storePrice = product?.Prices[storeName]
  if (!storePrice) return 0

  const isBulkPriceAvailable = storePrice.Discount && storePrice.Discount.MinQuantity > 0 && storePrice.Discount.MinQuantity <= quantity
  if (!isBulkPriceAvailable) return quantity * storePrice.Price
  const unitPrice = (storePrice.Price - storePrice.Discount.SavedPerItem)
  const quantitiesWithoutDiscount = quantity % storePrice.Discount.MinQuantity
  const quantitiesWithDiscount = quantity - quantitiesWithoutDiscount
  const price = quantitiesWithDiscount * unitPrice + quantitiesWithoutDiscount * storePrice.Price
  return price
}


export async function getSharedWishList(wishListId: string): Promise<Wish[]> {
  const res = await fetch("/api/shareList?wishListId=" + wishListId, {
    method: "GET",
  })
  const { wishList } = await res.json()
  return wishList
}