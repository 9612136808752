/* eslint-disable no-irregular-whitespace */
import React from "react"


interface Co2Props {
  co2: number
  className?: string
  style?: React.CSSProperties
}

export default function Co2({ co2, className, style }: Co2Props): JSX.Element {
  return (
    <span className={["co2", className].join(" ")} style={style}>
      <span className="co2value">{co2.toLocaleString("da-DK")}</span> <small>kg CO<sub>2</sub></small>
    </span>
  )
}

